<template>
<div class="home">
  <h1>Cometflare.com</h1>
</div>
</template>

<script>
export default {
  name: 'Home',
}
</script>

<style lang="scss" scoped>
div.home {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  h1 {
    position: absolute;
    top: calc(50% - 19px);
    left: calc(50% - 120px);
  }
}
</style>
